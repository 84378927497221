import { HIdColumnDto } from "src/app/models/hId-list/hid-column-dto"
import { AddHidResponse, ChainStoreResponse, HIdOutputDto } from "src/app/models/hId-list/hId-output-dto"
import { HIdActionTypes } from "./hid-list.actions"

export interface HIdState {
  hIdList: HIdOutputDto,
  columns: HIdColumnDto[],
  addHIDResult: AddHidResponse,
  viewHIDResult: any,
  chainListByHid: ChainStoreResponse
}

export const initialState =
{
  hIdList: null,
  columns: null,
  addHIDResult: null,
  viewHIDResult: null,
  chainListByHid: null
}

export function hIdListReducer(state = initialState, action: any): HIdState {
  switch (action.type) {
    case HIdActionTypes.GET_HID_LIST_RES: {
      return { ...state, hIdList: action.hIdList }
    }

    case HIdActionTypes.SET_HID_LIST_COLUMNS: {
      return { ...state, columns: action.columns }
    }

    case HIdActionTypes.ADD_HID_RES: {
      return { ...state, addHIDResult: action.addHidResponse }
    }

    case HIdActionTypes.VIEW_HOSPITAL_CORPORATEID_RES: {
      return { ...state, viewHIDResult: action.result }
    }

    case HIdActionTypes.GET_CHAINBYHId_RES: {
      return { ...state, chainListByHid: action.chainListByHId }
    }

    case HIdActionTypes.CLEAR_HID_DATA: {
      return { ...state, chainListByHid: null, viewHIDResult: null, addHIDResult: null }
    }
    
    default:{
      return state;
    }
  }
}