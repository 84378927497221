import { PendingPackagesActionTypes } from './pending-packages.actions';
import { AccumulationsResponse } from 'src/app/models/Pending-Packages/accumulations-by-pid';
import { DrugCatalogResponse } from 'src/app/models/Pending-Packages/drugcatalog-by-pid';
import { PackagePidListResponse, PendingPackageByHidResponse, PendingPackageByNdcResponse } from 'src/app/models/Pending-Packages/pending-package-expiration';

export interface PendingPackagesState {
  packagesByHid: PendingPackageByHidResponse;
  packagesByNdc: PendingPackageByNdcResponse;
  accumulations: AccumulationsResponse;
  drugCatalog: DrugCatalogResponse;
  pidList: PackagePidListResponse;
  applyPackagesPidResponse: any;
  applyPackagesNdcResponse: any;
  successMessage: string;
  exportPPData: any;
}

export const initialState: PendingPackagesState = {
  packagesByHid: null,
  packagesByNdc: null,
  accumulations: null,
  drugCatalog: null,
  pidList: null,
  applyPackagesPidResponse: null,
  applyPackagesNdcResponse: null,
  successMessage: null,
  exportPPData: null
};

export function PendingPackageReducer(state = initialState, action: any): PendingPackagesState {
  switch (action.type) {
    case PendingPackagesActionTypes.PACKAGES_BY_HID_RES: {
      return { ...state, packagesByHid: action.packagesByHid };
    }
    case PendingPackagesActionTypes.PACKAGES_BY_NDC_RES: {
      return { ...state, packagesByNdc: action.packagesByNdc };
    }
    case PendingPackagesActionTypes.ACCUMULATIONS_BY_PID_RES: {
      return { ...state, accumulations: action.accumulations };
    }
    case PendingPackagesActionTypes.DRUG_CATALOG_BY_PID_RES: {
      return { ...state, drugCatalog: action.drugCatalog };
    }
    case PendingPackagesActionTypes.PACKAGES_SHOW_PID_RES: {
      return { ...state, pidList: action.pidList };
    }
    case PendingPackagesActionTypes.APPLY_PACKAGES_PID_RES: {
      return { ...state, applyPackagesPidResponse: action.applyPackagesPidResponse }
    }
    case PendingPackagesActionTypes.APPLY_PACKAGES_NDC_RES: {
      return { ...state, applyPackagesNdcResponse: action.applyPackagesNdcResponse }
    }
    case PendingPackagesActionTypes.DELETE_PACKAGES_RES: {
      return { ...state, successMessage: action.successMessage }
    }
    case PendingPackagesActionTypes.EXPORT_PACKAGES_BY_HID_RES: {
      return { ...state, exportPPData: action.result }
    }
    default: {
      return state;
    }
  }
}
