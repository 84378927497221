import { Injectable } from '@angular/core';
import { of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UAMService } from '../../service/uam.service';
import {
  GetRoles, GetRolesResults, GetRolesError, GetEntityGroups, GetEntityGroupsResults, GetEntityGroupsError,
  GetUsersResults, GetUsers, GetUsersError, GetPermissions, GetPermissionsResults, GetPermissionsError,
  UpdateRoleResults, UpdateRoleError, UpdateRole, UAMActionTypes, DeleteRoles, DeleteRolesResults, AddUpdateUser, AddUpdateUserResults, AddUpdateUserError,
  GetPermissionsByRole, GetPermissionsByRoleResults, GetPermissionsByRoleError, GetClients, GetClientsResults, GetClientsError,
  GetEntityGroupsHospital, GetEntityGroupsHospitalResults, GetEntityGroupsHospitalError, AddEntityGroupMembers, AddEntityGroupMembersResults, DeleteEntityGroupsResults, DeleteUserGroup, DeleteUserGroupError,
  DeleteEntityGroups, DeleteEntityGroupsError, AddEntityGroupMembersError, GetEntityGroupsPharmacy, GetEntityGroupsPharmacyError, GetEntityGroupsPharmacyResults, GetUserGroups, GetUserGroupsResults,
  GetUserGroupsError, EntityNameValidation, EntityNameValidationResults, EntityNameValidationError, DeleteUserGroupResult, SearchCorpsChainGroups, SearchCorpsChainGroupsResults, SearchCorpsChainGroupsError
  , GetEntityGroupByID,
  GetEntityGroupByIDResults,
  GetEntityGroupByIDError,
  UpdateUserGroups,
  UpdateUserGroupsResults,
  UpdateUserGroupsError,
  GetUserGroupByID,
  GetUserGroupByIDResults,
  GetUserGroupByIDError,
  RoleNameValidation,
  RoleNameValidationResults,
  RoleNameValidationError,
  GetUserDetails,
  GetUserDetailsResults,
  GetUserDetailsError,
  UserGroupNameValidation,
  UserGroupNameValidationResults,
  UserGroupNameValidationError,
  GetUserEntityRoles,
  GetUserEntityRolesResults,
  GetUserEntityRolesError,
  DeleteUserGroupMember,
  DeleteUserGroupMemberResult,
  DeleteUserGroupMemberError,
  AddUserEntityRole,
  AddUserEntityRoleResults,
  AddUserEntityRoleError,
  DeleteUserEntityRole,
  DeleteUserEntityRoleResults,
  DeleteUserEntityRoleError,
  GetCorporateParent,
  GetCorporateParentResults,
  GetCorporateParentError,
  GetChainGroup,
  GetChainGroupResults,
  GetChainGroupError,
  GetUserClientAccessData,
  GetUserClientAccessDataResults,
  GetUserClientAccessDataError,
  AddUserClientAccessData,
  AddUserClientAccessDataResults,
  AddUserClientAccessDataError,
  ValidateUserDetails,
  ValidateUserDetailsResults,
  ValidateUserDetailsError,
  GetProductClientFeatures,
  GetProductClientFeaturesResults,
  GetProductClientFeaturesError,
  GetNonparentalEntityGroups,
  GetNonparentalEntityGroupsResults,
  GetNonparentalEntityGroupsError,
  ResendUserInvite,
  ResendUserInviteResults,
  ResendUserInviteError,
  UserEntityRoleValidation,
  UserEntityRoleValidationResults,
  UserEntityRoleValidationError,
  AddorDeleteProductFeatures,
  AddorDeleteProductFeaturesResults,
  AddorDeleteProductFeaturesError,
  UpdateUserEntityRole,
  UpdateUserEntityRoleResults,
  UpdateUserEntityRoleError,
  GetAllUsers,
  GetAllUsersError,
  GetAllUsersResults,
  BulkUserUpload,
  BulkUserUploadResults,
  BulkUserUploadError,
  GetBulkUserUploadList,
  GetBulkUserUploadListError,
  GetBulkUserUploadListResults,
  GetBulkUserErrorDetail,
  GetBulkUserErrorDetailResults,
  GetBulkUserErrorDetailError,
  BulkDeleteUserGroups,
  BulkDeleteUserGroupsResults,
  BulkDeleteUserGroupsError,
  BulkDeleteEntityGroups,
  BulkDeleteEntityGroupsResults,
  BulkDeleteEntityGroupsError,
  GetUserGroupsByCorpId,
  GetUserGroupsByCorpIdError,
  GetUserGroupsByCorpIdResults,
  GetTempAccessData,
  GetTempAccessDataResults,
  GetTempAccessDataError,
  RequestTempAccess,
  RequestTempAccessResults,
  RequestTempAccessError,
  BulkDeleteRoles,
  BulkDeleteRolesResults,
  BulkDeleteRolesError,
  DeleteUser,
  DeleteUserResult,
  DeleteUserError,
  GetUserGroupHistory,
  GetUserGroupHistoryResult,
  GetUserGroupHistoryError,
  ViewEntityGroupHistory,
  ViewEntityGroupHistoryResult,
  ViewEntityGroupHistoryError,
  GetUserHistory,
  GetUserHistoryError,
  GetUserHistoryResult,
  ViewRolesHistory,
  ViewRolesHistoryResult,
  ViewRolesHistoryError,
  AddAdminContact,
  AddAdminContactResult,
  AddAdminContactError,
  GetAdminContact,
  GetAdminContactResult,
  GetAdminContactError,
} from './uam.actions';
import { FileUploadResponse, User, UserRequest } from 'src/app/models/User-Access-Management/user';
import { BulkUserErrorDetailResponse, BulkUserUploadListItem, BulkUserUploadListResponse } from '../../models/User-Access-Management/bullk-user-upload';

@Injectable()
export class UAMEffects {
  constructor(private UAMService: UAMService, private actions$: Actions) { }

  GetRolesEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetRoles),
    switchMap(() =>
      this.UAMService.getRoles()
        .pipe(
          map(response => GetRolesResults({ roles: response })),
          catchError(error => {
            return observableOf(GetRolesError({ errorMessage: error }))
          })
        )
    )
  ));

  UpdateRoleEffect$ = createEffect(() => this.actions$.pipe(
    ofType(UpdateRole),
    switchMap(action =>
      this.UAMService.updateRole(action.role)
        .pipe(
          map(response => UpdateRoleResults({ response })),
          catchError(error => {
            return observableOf(UpdateRoleError({ errorMessage: error }))
          })
        )
    )
  ));

  GetPermissionsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetPermissions),
    switchMap(() =>
      this.UAMService.getPermissions()
        .pipe(
          map(data => GetPermissionsResults({ response: data })),
          catchError(error => {
            return observableOf(GetPermissionsError({ errorMessage: error }))
          })
        )
    )
  ));

  GetRolePermissionsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetPermissionsByRole),
    switchMap((action) =>
      this.UAMService.getPermissionsByRole(action.roleId)
        .pipe(
          map(data => GetPermissionsByRoleResults({ response: data })),
          catchError(error => {
            return observableOf(GetPermissionsByRoleError({ errorMessage: error }))
          })
        )
    )
  ));

  ModifiedRoleResultsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(UAMActionTypes.ROLE_UPDATE_RES, UAMActionTypes.ROLE_DELETE_RES),
    switchMap(action => {
      return observableOf(GetRoles())
    }
    )
  ));

  GetEntityGroupsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetEntityGroups),
    switchMap(() =>
      this.UAMService.getEntityGroups()
        .pipe(
          map(data => GetEntityGroupsResults({ entityGroups: data })),
          catchError(error => {
            return observableOf(GetEntityGroupsError({ errorMessage: error }))
          })
        )
    )
  ));


  GetNonparentalEntityGroupsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetNonparentalEntityGroups),
    switchMap((action) =>
      this.UAMService.getNonparentalEntityGroups(action.entityGroupId, action.IsInternal)
        .pipe(
          map(data => GetNonparentalEntityGroupsResults({ entityGroups: data })),
          catchError(error => {
            return observableOf(GetNonparentalEntityGroupsError({ errorMessage: error }))
          })
        )
    )
  ));


  GetUsersEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetUsers),
    switchMap(() =>
      this.UAMService.getUsers()
        .pipe(
          map((res: User[]) => GetUsersResults({ data: res })),
          catchError(error => {
            return observableOf(GetUsersError({ errorMessage: error }))
          })
        )
    )
  ));


  DeleteRoleEffect$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteRoles),
    switchMap((action) =>
      this.UAMService.deleteRoles(action.id)
        .pipe(
          map((res => DeleteRolesResults({ response: res }))),
          catchError(error => {
            return observableOf(GetRolesError({ errorMessage: error }))
          })
        )
    )
  ));

  DeleteEntityGroupEffect$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteEntityGroups),
    switchMap((action) =>
      this.UAMService.deleteEntityGroups(action.entityGroupId)
        .pipe(
          map((response => DeleteEntityGroupsResults({ response }))),
          catchError(error => {
            return observableOf(DeleteEntityGroupsError({ errorMessage: error }))
          })
        )
    )
  ));

  AddUserEffect$ = createEffect(() => this.actions$.pipe(
    ofType(AddUpdateUser),
    switchMap(action =>
      this.UAMService.addUpdateUser(action.newUser)
        .pipe(
          map(
            data => {
              return AddUpdateUserResults({ newUser: data })
            }
          ),
          catchError(
            error => observableOf(AddUpdateUserError({ errorMessage: error }))
          )
        )
    )
  ));

  GetClients$ = createEffect(() => this.actions$.pipe(
    ofType(GetClients),
    switchMap(() =>
      this.UAMService.getClients()
        .pipe(
          map(response => GetClientsResults({ response: response })),
          catchError(error => {
            return observableOf(GetClientsError({ errorMessage: error }))
          })
        )
    )
  ));

  GetEntityGroupsHospitalEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetEntityGroupsHospital),
    switchMap(() =>
      this.UAMService.getHospitalEntityGroups()
        .pipe(
          map(data => GetEntityGroupsHospitalResults({ entityGroupsHospitalList: data })),
          catchError(error => {
            return observableOf(GetEntityGroupsHospitalError({ errorMessage: error }))
          })
        )
    )
  ));

  AddEntityGroupMemberEffect$ = createEffect(() => this.actions$.pipe(
    ofType(AddEntityGroupMembers),
    switchMap(action =>
      this.UAMService.addEntityGroupMembers(action.entityGroupsHospitalRequest)
        .pipe(
          map(
            data => {
              return AddEntityGroupMembersResults({ result: data })
            }
          ),
          catchError(
            error => observableOf(AddEntityGroupMembersError({ errorMessage: error }))
          )
        )
    )
  ));

  GetEntityGroupsParmacyEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetEntityGroupsPharmacy),
    switchMap(() =>
      this.UAMService.getPharmacyEntityGroups()
        .pipe(
          map(data => GetEntityGroupsPharmacyResults({ entityGroupPharmacyList: data })),
          catchError(error => {
            return observableOf(GetEntityGroupsPharmacyError({ errorMessage: error }))
          })
        )
    )
  ));


  GetUserGroupsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetUserGroups),
    switchMap(() =>
      this.UAMService.getUserGroups()
        .pipe(
          map(data => GetUserGroupsResults({ userGroupsList: data })),
          catchError(error => {
            return observableOf(GetUserGroupsError({ errorMessage: error }))
          })
        )
    )
  ));


  DeleteUserGroupEffect$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteUserGroup),
    switchMap((action) =>
      this.UAMService.deleteUserGroup(action.userGroupId)
        .pipe(
          map((response => DeleteUserGroupResult({ response }))),
          catchError(error => {
            return observableOf(DeleteUserGroupError({ errorMessage: error }))
          })
        )
    )
  ));

  EntityNameValidationEffect$ = createEffect(() => this.actions$.pipe(
    ofType(EntityNameValidation),
    switchMap((action) =>
      this.UAMService.entityNameValidation(action.entityName)
        .pipe(
          map(data => EntityNameValidationResults({ result: data })),
          catchError(error => {
            return observableOf(EntityNameValidationError({ errorMessage: error }))
          })
        )
    )
  ));

  SearchCorpsChainGroups$ = createEffect(() => this.actions$.pipe(
    ofType(SearchCorpsChainGroups),
    switchMap((action) =>
      this.UAMService.searchCorpsChainGroups(action.keyword, action.searchType)
        .pipe(
          map(response => SearchCorpsChainGroupsResults({ response: response })),
          catchError(error => {
            return observableOf(SearchCorpsChainGroupsError({ errorMessage: error }))
          })
        )
    )
  ));

  GetEntityGroupByIDEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetEntityGroupByID),
    switchMap((action) =>
      this.UAMService.getEntityGroupByID(action.entityGroupID)
        .pipe(
          map(data => GetEntityGroupByIDResults({ entityGroupResponse: data })),
          catchError(error => {
            return observableOf(GetEntityGroupByIDError({ errorMessage: error }))
          })
        )
    )
  ));

  UpdateUserGroupEffect$ = createEffect(() => this.actions$.pipe(
    ofType(UpdateUserGroups),
    switchMap((action) =>
      this.UAMService.updateUserGroup(action.userGroupRequest)
        .pipe(
          map(data => UpdateUserGroupsResults({ result: data })),
          catchError(error => {
            return observableOf(UpdateUserGroupsError({ errorMessage: error }))
          })
        )
    )
  ));

  GetUserGroupByIDEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetUserGroupByID),
    switchMap((action) =>
      this.UAMService.getUserGroupByID(action.userGroupID)
        .pipe(
          map(data => GetUserGroupByIDResults({ userGroupsResponse: data })),
          catchError(error => {
            return observableOf(GetUserGroupByIDError({ errorMessage: error }))
          })
        )
    )
  ));

  RoleNameValidationEffect$ = createEffect(() => this.actions$.pipe(
    ofType(RoleNameValidation),
    switchMap((action) =>
      this.UAMService.roleNameValidation(action.roleName)
        .pipe(
          map(data => RoleNameValidationResults({ result: data })),
          catchError(error => {
            return observableOf(RoleNameValidationError({ errorMessage: error }))
          })
        )
    )
  ));


  GetUserDetailsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetUserDetails),
    switchMap((action) =>
      this.UAMService.getUserDetails(action.userId)
        .pipe(
          map(data => GetUserDetailsResults({ userDetails: data })),
          catchError(error => {
            return observableOf(GetUserDetailsError({ errorMessage: error }))
          })
        )
    )
  ));

  UserGroupNameValidationEffect$ = createEffect(() => this.actions$.pipe(
    ofType(UserGroupNameValidation),
    switchMap((action) =>
      this.UAMService.userGroupNameValidation(action.userGroupName)
        .pipe(
          map(data => UserGroupNameValidationResults({ result: data })),
          catchError(error => {
            return observableOf(UserGroupNameValidationError({ errorMessage: error }))
          })
        )
    )
  ));

  DeleteUserGroupMemberEffect$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteUserGroupMember),
    switchMap((action) =>
      this.UAMService.deleteUserGroupById(action.userGroupMemberId)
        .pipe(
          map((response => DeleteUserGroupMemberResult({ response }))),
          catchError(error => {
            return observableOf(DeleteUserGroupMemberError({ errorMessage: error }))
          })
        )
    )
  ));

  GetUserEntityRolesEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetUserEntityRoles),
    switchMap(() =>
      this.UAMService.getUserEntityRoles()
        .pipe(
          map(response => GetUserEntityRolesResults({ response: response })),
          catchError(error => {
            return observableOf(GetUserEntityRolesError({ errorMessage: error }))
          })
        )
    )
  ));

  AddUserEntityRoleEffect$ = createEffect(() => this.actions$.pipe(
    ofType(AddUserEntityRole),
    switchMap(action =>
      this.UAMService.addUserEntityRole(action.userEntityRole)
        .pipe(
          map(
            data => {
              return AddUserEntityRoleResults({ response: data })
            }
          ),
          catchError(
            error => observableOf(AddUserEntityRoleError({ errorMessage: error }))
          )
        )
    )
  ));

  DeleteUserEntityRoleEffect$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteUserEntityRole),
    switchMap(action =>
      this.UAMService.deleteUserEntityRole(action.userEntityRole)
        .pipe(
          map(
            data => {
              return DeleteUserEntityRoleResults({ response: data })
            }
          ),
          catchError(
            error => observableOf(DeleteUserEntityRoleError({ errorMessage: error }))
          )
        )
    )
  ));

  ModifiedUserEntityRoleResultsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(UAMActionTypes.ADD_USER_ENTITY_ROLE_RES, UAMActionTypes.DELETE_USER_ENTITY_ROLE_RES, UAMActionTypes.UPDATE_USER_ENTITY_ROLE_RES),
    switchMap(action => {
      return observableOf(GetUserEntityRoles())
    }
    )
  ));

  GetCorporateParentUAMEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetCorporateParent),
    switchMap(() =>
      this.UAMService.getCorporateParentUAM()
        .pipe(
          map(data => GetCorporateParentResults({ corporateParentList: data })),
          catchError(error => {
            return observableOf(GetCorporateParentError({ errorMessage: error }))
          })
        )
    )
  ));

  GetChainGroupUAMEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetChainGroup),
    switchMap(() =>
      this.UAMService.getChainGroupUAM()
        .pipe(
          map(data => GetChainGroupResults({ chainGroupList: data })),
          catchError(error => {
            return observableOf(GetChainGroupError({ errorMessage: error }))
          })
        )
    )
  ));

  GetUserClientAccessDataEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetUserClientAccessData),
    switchMap((action) =>
      this.UAMService.getUserClientAccess(action.userId)
        .pipe(
          map(data => GetUserClientAccessDataResults({ userClientAccessResponse: data })),
          catchError(error => {
            return observableOf(GetUserClientAccessDataError({ errorMessage: error }))
          })
        )
    )
  ));

  AddUserClientAccessEffect$ = createEffect(() => this.actions$.pipe(
    ofType(AddUserClientAccessData),
    switchMap(action =>
      this.UAMService.updateUserClientAccess(action.userClientAccessRequest)
        .pipe(
          map(
            data => {
              return AddUserClientAccessDataResults({ response: data })
            }
          ),
          catchError(
            error => observableOf(AddUserClientAccessDataError({ errorMessage: error }))
          )
        )
    )
  ));

  ValidateUserDetailsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(ValidateUserDetails),
    switchMap(action =>
      this.UAMService.validateUserDetails(action.userdetails)
        .pipe(
          map(
            data => {
              return ValidateUserDetailsResults({ response: data })
            }
          ),
          catchError(
            error => observableOf(ValidateUserDetailsError({ errorMessage: error }))
          )
        )
    )
  ));

  GetProductClientFeatureEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetProductClientFeatures),
    switchMap((action =>
      this.UAMService.getProductClientFeaturePermissions(action.clientFeatureRequestById)
        .pipe(
          map(data => GetProductClientFeaturesResults({ productClientFeatureResp: data })),
          catchError(error => {
            return observableOf(GetProductClientFeaturesError({ errorMessage: error }))
          })
        )
    )
    )
  ));

  AddOrDeleteProductFeatureEffect$ = createEffect(() => this.actions$.pipe(
    ofType(AddorDeleteProductFeatures),
    switchMap((action =>
      this.UAMService.addOrDeleteProductFeaturePermission(action.productFeatureResp)
        .pipe(
          map(data => AddorDeleteProductFeaturesResults({ response: data })),
          catchError(error => {
            return observableOf(AddorDeleteProductFeaturesError({ errorMessage: error }))
          })
        )
    )
    )
  ));

  ResendUserInviteEffect$ = createEffect(() => this.actions$.pipe(
    ofType(ResendUserInvite),
    switchMap((action) =>
      this.UAMService.resendUserInvite(action.userId)
        .pipe(
          map(data => ResendUserInviteResults({ response: data })),
          catchError(error => {
            return observableOf(ResendUserInviteError({ errorMessage: error }))
          })
        )
    )
  ));

  UserEntityRoleValidationEffect$ = createEffect(() => this.actions$.pipe(
    ofType(UserEntityRoleValidation),
    switchMap((action) =>
      this.UAMService.userEntityRoleValidation(action.userEntityRole)
        .pipe(
          map(data => UserEntityRoleValidationResults({ result: data })),
          catchError(error => {
            return observableOf(UserEntityRoleValidationError({ errorMessage: error }))
          })
        )
    )
  ));

  UpdateUserEntityRoleEffect$ = createEffect(() => this.actions$.pipe(
    ofType(UpdateUserEntityRole),
    switchMap(action =>
      this.UAMService.UpdateUserEntityRole(action.userEntityRole)
        .pipe(
          map(
            data => {
              return UpdateUserEntityRoleResults({ response: data })
            }
          ),
          catchError(
            error => observableOf(UpdateUserEntityRoleError({ errorMessage: error }))
          )
        )
    )
  ));

  GetAllUsersEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetAllUsers),
    switchMap(() =>
      this.UAMService.getAllUsers()
        .pipe(
          map((res: User[]) => GetAllUsersResults({ data: res })),
          catchError(error => {
            return observableOf(GetAllUsersError({ errorMessage: error }))
          })
        )
    )
  ));

  BulkUserUploadEffect$ = createEffect(() => this.actions$.pipe(
    ofType(BulkUserUpload),
    switchMap(action =>
      this.UAMService.fileUploadUsers(action.file)
        .pipe(
          map((res: FileUploadResponse) => BulkUserUploadResults({ data: res })),
          catchError(error => {
            return observableOf(BulkUserUploadError({ errorMessage: error }))
          })
        )
    )
  ));


  GetBulkUserUploadList$ = createEffect(() => this.actions$.pipe(
    ofType(GetBulkUserUploadList),
    switchMap(() =>
      this.UAMService.getBulkUserUploadList()
        .pipe(
          map((res: BulkUserUploadListResponse) => GetBulkUserUploadListResults({ data: res })),
          catchError(error => {
            return observableOf(GetBulkUserUploadListError({ errorMessage: error }))
          })
        )
    )
  ));

  GetBulkUserErrorDetail$ = createEffect(() => this.actions$.pipe(
    ofType(GetBulkUserErrorDetail),
    switchMap(action =>
      this.UAMService.getBulkUserUploadErrorDetail(action.importId)
        .pipe(
          map((res: BulkUserErrorDetailResponse) => GetBulkUserErrorDetailResults({ data: res })),
          catchError(error => {
            return observableOf(GetBulkUserErrorDetailError({ errorMessage: error }))
          })
        )
    )
  ));

  BulkDeleteUserGroupEffect$ = createEffect(() => this.actions$.pipe(
    ofType(BulkDeleteUserGroups),
    switchMap((action) =>
      this.UAMService.deletebulkUserGroup(action.userGroupId)
        .pipe(
          map((response => BulkDeleteUserGroupsResults({ response }))),
          catchError(error => {
            return observableOf(BulkDeleteUserGroupsError({ errorMessage: error }))
          })
        )
    )
  ));

  GetUserGroupsByCorpIdEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetUserGroupsByCorpId),
    switchMap((action) =>
      this.UAMService.getUserGroupsByCorpId(action.corpId, action.csgId)
        .pipe(
          map(data => GetUserGroupsByCorpIdResults({ userGroupsList: data })),
          catchError(error => {
            return observableOf(GetUserGroupsByCorpIdError({ errorMessage: error }))
          })
        )
    )
  ));

  BulkDeleteEntityGroupEffect$ = createEffect(() => this.actions$.pipe(
    ofType(BulkDeleteEntityGroups),
    switchMap((action) =>
      this.UAMService.deletebulkEntityGroup(action.entityGroupId)
        .pipe(
          map((response => BulkDeleteEntityGroupsResults({ response }))),
          catchError(error => {
            return observableOf(BulkDeleteEntityGroupsError({ errorMessage: error }))
          })
        )
    )
  ));

  GetTempAccessDataEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetTempAccessData),
    switchMap((action) =>
      this.UAMService.getTempAccessData(action.userGroupId)
        .pipe(
          map(data => GetTempAccessDataResults({ tempAccessData: data })),
          catchError(error => {
            return observableOf(GetTempAccessDataError({ errorMessage: error }))
          })
        )
    )
  ));

  RequestTempAccessEffect$ = createEffect(() => this.actions$.pipe(
    ofType(RequestTempAccess),
    switchMap((action) =>
      this.UAMService.requestTempAccess(action.tempAccessModel)
        .pipe(
          map(data => RequestTempAccessResults({ tempAccessResponse: data })),
          catchError(error => {
            return observableOf(RequestTempAccessError({ errorMessage: error }))
          })
        )
    )
  ));


  BulkDeleteRoleEffect$ = createEffect(() => this.actions$.pipe(
    ofType(BulkDeleteRoles),
    switchMap((action) =>
      this.UAMService.deleteBulkRoles(action.roleIds)
        .pipe(
          map((response => BulkDeleteRolesResults({ response }))),
          catchError(error => {
            return observableOf(BulkDeleteRolesError({ errorMessage: error }))
          })
        )
    )
  ));

  DeleteUserEffect$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteUser),
    switchMap((action) =>
      this.UAMService.deleteUser(action.userId)
        .pipe(
          map((response => DeleteUserResult({ response }))),
          catchError(error => {
            return observableOf(DeleteUserError({ errorMessage: error }))
          })
        )
    )
  ));

  GetUserGroupHistory$ = createEffect(() => this.actions$.pipe(
    ofType(GetUserGroupHistory),
    switchMap((action) =>
      this.UAMService.getUserGroupHistory(action.userGroupId)
        .pipe(
          map((response => GetUserGroupHistoryResult({ response }))),
          catchError(error => {
            return observableOf(GetUserGroupHistoryError({ errorMessage: error }))
          })
        )
    )
  ));

  ViewEntityGroupHistoryEffect$ = createEffect(() => this.actions$.pipe(
    ofType(ViewEntityGroupHistory),
    switchMap((action) =>
      this.UAMService.ViewEntityGroupHistory(action.entityGroupId)
        .pipe(
          map((response => ViewEntityGroupHistoryResult({ viewHistoryresponse: response }))),
          catchError(error => {
            return observableOf(ViewEntityGroupHistoryError({ errorMessage: error }))
          })
        )
    )
  ));

  GetUserHistory$ = createEffect(() => this.actions$.pipe(
    ofType(GetUserHistory),
    switchMap((action) =>
      this.UAMService.getUserHistory(action.userId)
        .pipe(
          map((response => GetUserHistoryResult({ response }))),
          catchError(error => {
            return observableOf(GetUserHistoryError({ errorMessage: error }))
          })
      )
    )
  ));
    
  RolesHistoryEffect$ = createEffect(() => this.actions$.pipe(
    ofType(ViewRolesHistory),
    switchMap((action) =>
      this.UAMService.ViewRolesHistory(action.roleId)
        .pipe(
          map((response => ViewRolesHistoryResult({ roleHistoryresponse:  response }))),
          catchError(error => {
            return observableOf(ViewRolesHistoryError({ errorMessage: error }))
          })
        )
      )
    ));
    
  AddAdminContactEffect$ = createEffect(() => this.actions$.pipe(
    ofType(AddAdminContact),
    switchMap((action =>
      this.UAMService.addAdminContact(action.adminContact)
        .pipe(
          map(data => AddAdminContactResult({ response: data })),
          catchError(error => {
            return observableOf(AddAdminContactError({ errorMessage: error }))
          })
        )
      )
    )
  ));


  GetAdminContactEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetAdminContact),
    switchMap(() =>
      this.UAMService.getAdminContact()
        .pipe(
          map(response => GetAdminContactResult({ response })),
          catchError(error => {
            return observableOf(GetAdminContactError({ errorMessage: error }))
          })
        )
    )
  ));
}

