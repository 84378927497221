<h2 class=" mat-dialog-title">Health System Contact Designation</h2>
<div style="margin-left: 5px;"  cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="font16" style="margin-top: 8px; margin-bottom: 10px;">Please choose who you would like to designate as a contact for your health system. These user(s) will be contacted for approvals based on the type you choose. </div>
    
</div>

<table-dropdown-admincontact [dropdownData]="formField1" [isAdminConfirm]="isAdminConfirm" (closeSelection)="formField1_selected($event)"  style="align-content: center;margin-top: 10px;"></table-dropdown-admincontact>
<table-dropdown-admincontact [dropdownData]="formField2" [isAdminConfirm]="isAdminConfirm" (closeSelection)="formField2_selected($event)"></table-dropdown-admincontact>
<table-dropdown-admincontact [dropdownData]="formField3" [isAdminConfirm]="isAdminConfirm" (closeSelection)="formField3_selected($event)"></table-dropdown-admincontact>

<div>
<div class="warning">
    <label>
        <input type="checkbox"  (change)="adminSelection($event)"/>
        I do not know who my administrators are at this time <br />
        Once your administrators are determined, please update this information within User Access Management Page “Update Administrator”. This helps our team know who to contact with support questions and any communications we send out.
    </label>
</div>
    <span  class="component-margin" style="padding-left: 35%;">
      <button mat-stroked-button type="button" class="primary-button " (click)="onSaveChanges()">Apply</button>
  </span>
  
  <span  class="component-margin" style="padding-left: 5%;">
      <button mat-stroked-button type="button" class="secondary-button" (click)="onCloseClick()">Close</button>
  </span>  
  </div> 