import { createAction, props } from '@ngrx/store';
import { ExclusionItem, GetPharmacyResponse } from 'src/app/models/QualificationFilter'
import { BINExclusionResponse, FileUploadResponse, Status } from '../models/submit-response';
import { Pharmacy } from '../models/pharmacy';


export enum QualificationFilterTypes {
  PHARMACY_GET = '[Rest Call] [BulkBinExclusionAPI] GetPharmaciesByUser',
  PHARMACY_GET_RES = '[Rest Result] [BulkBinExclusionAPI] GetPharmaciesByUserRes',
  PHARMACY_GET_ERR = '[Rest Error] [BulkBinExclusionAPI] GetPharmaciesByUserErr',

  ADD_BINEXCLUSIONS = '[Rest Call] [BulkBinExclusionAPI] AddBINExclusions',
  ADD_BINEXCLUSIONS_RES = '[Rest Result] [BulkBinExclusionAPI] AddBINExclusionsRes',
  ADD_BINEXCLUSIONS_ERR = '[Rest Error] [BulkBinExclusionAPI] AddBINExclusionsErr',

  FILEUPLOAD_BINEXCLUSIONS = '[Rest Call] [BulkBinExclusionAPI] FileUpload',
  FILEUPLOAD_BINEXCLUSIONS_RES = '[Rest Result] [BulkBinExclusionAPI] FileUploadRes',
  FILEUPLOAD_BINEXCLUSIONS_ERR = '[Rest Error] [BulkBinExclusionAPI] FileUploadErr',

  FILEUPLOAD_NDCEXCLUSIONS = '[Rest Call] [BulkBinExclusionAPI] FileUploadNDCExclusion',
  FILEUPLOAD_NDCEXCLUSIONS_RES = '[Rest Result] [BulkBinExclusionAPI] FileUploadNDCExclusionRes',
  FILEUPLOAD_NDCEXCLUSIONS_ERR = '[Rest Error] [BulkBinExclusionAPI] FileUploadNDCExclusionErr',
}

export const GetPharmaciesByUser = createAction(QualificationFilterTypes.PHARMACY_GET, props<{ actionID: number, corpID: number }>());
export const GetPharmaciesByUserResults = createAction(QualificationFilterTypes.PHARMACY_GET_RES, props<{ pharmacyResponse: GetPharmacyResponse }>());
export const GetPharmaciesByUserError = createAction(QualificationFilterTypes.PHARMACY_GET_ERR, props<{ errorMessage: string }>());

export const AddBINExclusions = createAction(QualificationFilterTypes.ADD_BINEXCLUSIONS, props<{ data: { "items" : ExclusionItem[] }}>());
export const AddBINExclusionsResults = createAction(QualificationFilterTypes.ADD_BINEXCLUSIONS_RES, props<{ response: BINExclusionResponse }>());
export const AddBINExclusionsError = createAction(QualificationFilterTypes.ADD_BINEXCLUSIONS_ERR, props<{ response: BINExclusionResponse }>());

export const FileUploadBinExclusions = createAction(QualificationFilterTypes.FILEUPLOAD_BINEXCLUSIONS, props<{ postedFile: FormData }>());
export const FileUploadBinExclusionsResults = createAction(QualificationFilterTypes.FILEUPLOAD_BINEXCLUSIONS_RES, props<{ response: FileUploadResponse }>());
export const FileUploadBinExclusionsError = createAction(QualificationFilterTypes.FILEUPLOAD_BINEXCLUSIONS_ERR, props<{ response: FileUploadResponse }>());

export const FileUploadNDCExclusions = createAction(QualificationFilterTypes.FILEUPLOAD_NDCEXCLUSIONS, props<{ postedFile: FormData, exclusionType: number, clientID: number }>());
export const FileUploadNDCExclusionsResults = createAction(QualificationFilterTypes.FILEUPLOAD_NDCEXCLUSIONS_RES, props<{ response: Status }>());
export const FileUploadNDCExclusionsError = createAction(QualificationFilterTypes.FILEUPLOAD_NDCEXCLUSIONS_ERR, props<{ errorMessage: string }>());
