import { createAction, props } from "@ngrx/store";
import { ContactSearch, } from "src/app/models/corpid-list/contactsearch";
import { CorpIdColumnDto } from "src/app/models/corpid-list/corpid";
import { CorpIdOutputDto } from "src/app/models/corpid-list/corpid";
import { AccountManagerOutput, CorporateParent, NameValidate, ValidationData } from "src/app/models/corpid-list/corporateparent";

export enum CorpIdActionTypes {
  GET_CORPID_LIST = "[Rest Call] [corpIdList] GetCorpIdList",
  GET_CORPID_LIST_RES = "[Rest Result] [corpIdList] GetCorpIdList",
  GET_CORPID_LIST_ERR = "[Rest Error] [corpIdList] GetCorpIdList",

  SET_CORPID_LIST_COLUMNS = "[Rest Call] [corpIdList] SetCorpListColumns",
  SET_CORPID_LIST_COLUMNS_RES = "[Rest Result] [corpIdList] SetCorpListColumnsResults",
  SET_CORPID_LIST_COLUMNS_ERR = "[Rest Error] [corpIdList] SetCorpListColumnsError",

  SET_CORPORATE_PARENT_COLUMNS = "[Rest Call] [corpIdList] SetCorporateParentColumns",
  SET_CORPORATE_PARENT_COLUMNS_RES = "[Rest Result] [corpIdList] SetCorporateParentColumnsResults",
  SET_CORPORATE_PARENT_COLUMNS_ERR = "[Rest Error] [corpIdList] SetCorporateParentColumnsError",

  ADD_CORPORATE_PARENT = "[Rest Call] [corpIdList] AddCorporateParent",
  ADD_CORPORATE_PARENT_RES = "[Rest Result] [corpIdList] AddCorporateParentResults",
  ADD_CORPORATE_PARENT_ERR = "[Rest Error] [corpIdList] AddCorporateParentError",

  VIEW_CORPORATE_PARENT = "[Rest Call] [corpIdList] ViewCorporateParent",
  VIEW_CORPORATE_PARENT_RES = "[Rest Result] [corpIdList] ViewCorporateParentResults",
  VIEW_CORPORATE_PARENT_ERR = "[Rest Error] [corpIdList] ViewCorporateParentError",


  GET_CONTACT_SEARCH = '[Rest Call] [corpIdList] GetContactSearch',
  GET_CONTACT_SEARCH_RES = '[Rest Result] [corpIdList] GetContactSearchResults',
  GET_CONTACT_SEARCH_ERR = '[Rest Error] [corpIdList] GetContactSearchError',

  IS_VALID_CORPNAME = '[Rest Call] [corpIdList] IsValidCorpName',
  IS_VALID_CORPNAME_RES = '[Rest Result] [corpIdList] IsValidCorpNameResults',
  IS_VALID_CORPNAME_ERR = '[Rest Error] [corpIdList] IsValidCorpNameError',

  GET_ACCOUNT_MANAGER = '[Silent Rest Call] [corpIdList] GetAccountManagerList',
  GET_ACCOUNT_MANAGER_RES = '[Silent Rest Result] [corpIdList] GetAccountManagerListResults',
  GET_ACCOUNT_MANAGER_ERR = '[Silent Rest Error] [corpIdList] GetAccountManagerListError',

  EXPORT_DATA = "[Rest Call] [corpIdList] ExportData",
  EXPORT_DATA_RES = "[Rest Result] [corpIdList] ExportDataResults",
  EXPORT_DATA_ERR = "[Rest Result] [corpIdList] ExportDataError",

  CLEAR_CORPID_DATA = "[corpIdList] ClearData"

}

export const GetCorpIdList = createAction(CorpIdActionTypes.GET_CORPID_LIST, props<{}>());
export const GetCorpIdListResults = createAction(CorpIdActionTypes.GET_CORPID_LIST_RES, props<{ corpIdList: CorpIdOutputDto }>());
export const GetCorpIdListError = createAction(CorpIdActionTypes.GET_CORPID_LIST_ERR, props<{ message: string }>());

export const SetCorpIdColumns = createAction(CorpIdActionTypes.SET_CORPID_LIST_COLUMNS, props<{ columns: CorpIdColumnDto[] }>());
export const SetCorpIdColumnsResults = createAction(CorpIdActionTypes.SET_CORPID_LIST_COLUMNS_RES, props<{ columns: CorpIdColumnDto[] }>());
export const SetCorpIdColumnsError = createAction(CorpIdActionTypes.SET_CORPID_LIST_COLUMNS_ERR, props<{ message: string }>());

export const AddCorporateParent = createAction(CorpIdActionTypes.ADD_CORPORATE_PARENT, props<{ newCorporateParent: CorporateParent }>());
export const AddCorporateParentResults = createAction(CorpIdActionTypes.ADD_CORPORATE_PARENT_RES, props<{ result: any }>());
export const AddCorporateParentError = createAction(CorpIdActionTypes.ADD_CORPORATE_PARENT_ERR, props<{ message: string }>());

export const ViewCorporateParent = createAction(CorpIdActionTypes.VIEW_CORPORATE_PARENT, props<{ corpId: number }>());
export const ViewCorporateParentResults = createAction(CorpIdActionTypes.VIEW_CORPORATE_PARENT_RES, props<{ result: any }>());
export const ViewCorporateParentError = createAction(CorpIdActionTypes.VIEW_CORPORATE_PARENT_ERR, props<{ message: string }>());


export const GetContactSearch = createAction(CorpIdActionTypes.GET_CONTACT_SEARCH, props<{ contactSearch: string, corpId: number }>());
export const GetContactSearchResults = createAction(CorpIdActionTypes.GET_CONTACT_SEARCH_RES, props<{ contactSearch: ContactSearch }>());
export const GetContactSearchError = createAction(CorpIdActionTypes.GET_CONTACT_SEARCH_ERR, props<{ message: string }>());

export const IsValidCorpName = createAction(CorpIdActionTypes.IS_VALID_CORPNAME, props<{ corpname: NameValidate }>());
export const IsValidCorpNameResults = createAction(CorpIdActionTypes.IS_VALID_CORPNAME_RES, props<{ result: ValidationData }>());
export const IsValidCorpNameError = createAction(CorpIdActionTypes.IS_VALID_CORPNAME_ERR, props<{ message: string }>());

export const GetAccountManagerList = createAction(CorpIdActionTypes.GET_ACCOUNT_MANAGER, props<{}>());
export const GetAccountManagerListResults = createAction(CorpIdActionTypes.GET_ACCOUNT_MANAGER_RES, props<{ accountManager: AccountManagerOutput }>());
export const GetAccountManagerListError = createAction(CorpIdActionTypes.GET_ACCOUNT_MANAGER_ERR, props<{ message: string }>());

export const ExportData = createAction(CorpIdActionTypes.EXPORT_DATA, props<{ corpId: number, pageName: string }>());
export const ExportDataResults = createAction(CorpIdActionTypes.EXPORT_DATA_RES, props<{ result: any }>());
export const ExportDataError = createAction(CorpIdActionTypes.EXPORT_DATA_ERR, props<{ message: string }>());

export const ClearCorpIdData = createAction(CorpIdActionTypes.CLEAR_CORPID_DATA);