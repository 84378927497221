
export class TableGetOptions {
  uid: number;
  applicationId: number;
  searchText: string;
  pageNumber: number;
  rowsPerPage: number;
  sortColumn: string;
  sortOrder: string;
}

export class vmCD_Group_View {
  CD_SplitGroupID: number;
  CD_GroupDescription: string;
  TerminalAccount: string;
  SplitSequence: string;
  SplitSequencebyPrice: number;
  Vendor: string;
  GPOExemptExcludePID: number;
  SequenceID: number;
  VID: number;
  TerminalAccountPID: number;
  CDGStatus: number; 
  AutoRanking: number;
  AutoRankingDay: number;
}

export class vmCD_View_Pid_Data {
  Rank: number;
  PID: number;
  HID: number; 
  PharmacyName: string;
  CDGroupStatus: number;
  PIDStatus: number;
  pharmType: number;
  CDSplitDetailID: number;
  ExcludeTerminalInvoice: number;
  enRemove: number;
}

export class vmCD_Group_View_Details {
  CDGData: vmCD_Group_View;
  PidData: vmCD_View_Pid_Data[];
  CDContacts: vmCD_Contact[];
}

export class vmCD_Group_Vendor {
  VID: number;
  Vendor: string;
  PID: number;
  PAStatus: number;
  AccountStatus: number;
  GroupType: number;
  AccountMismatch: number;
  ValidAccount: number;
  CDExists: number;
}

export class vmCD_Group_Sequence {
  SequenceID: number;
  Sequence: string;
}

export class saveCDGroup {
  cD_SplitGroupID: number;
  cD_GroupDescription: string;
  terminalAccount: string;
  splitSequencebyPrice: number;
  gpoExemptExcludePID: number;
  sequenceID: number; 
  vid: number;
  terminalAccountPID: number;
  cdgStatus: number;
  userId: number;
  PidData: vmCD_View_Pid_Data[];
  CDContacts: vmCD_Contact[];
  PIDCount : number;
  ContactCount: number;
  autoRanking: number;
  autoRankingDay:number;
}

export class vmCD_Group_TerminalAccount
{
  TerminalAccount: string;
  SequenceID: number;
  AccountStatus: number;
  VID: number;
}

export class CD_GroupName {
  CD_GroupDescription: string; 
  countval: number;
}

export class vmCD_Contact{
  UID: number;
  FirstName: string;
  LastName: string;
  Email: string;
  UIDActive: number;
}

export class CD_PidList {
  PIDList: number[];
  searchString: string;
}
